@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap);
html,
body {
    height: 100%;
    margin: 0;
    padding: 0;

    font-family: Segoe UI, Frutiger, Frutiger Linotype, Dejavu Sans, Helvetica Neue, Arial, sans-serif;

    background-color: #2c2c41;

    font-size: 16px;
}

@media (min-width: 1280px) { 
    html, body {
        font-size: 14px;
    }
}

#root {
    display: flex;
    flex-direction: column;
    height: 100%;
}

* {
    /* box-sizing: border-box; */
    /* border: 1px solid red; */
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus {
     outline: none;
}

.dark-select .MuiOutlinedInput-root {
    height: 20px;
    border-radius: 50px!important;
}

.dark-select .MuiOutlinedInput-root:hover {
    background: #1c1d2d;
}

.dark-select > div {
    background-color: #31334E;
    color: #606178;
    font-size: 14px;
}

.dark-select svg {
    color: #606178;
}

.day-picker .MuiCheckbox-root {
    padding-left: 0;
    padding-right: 5px;
}

.padding-top-small {
    padding-top: 10px;
}

.margin-bottom-small {
    margin-bottom: 10px;
}

.margin-left-negative-tiny {
    margin-left: -5px;
}

.break {
    flex-basis: 100%;
    height: 0;
}

.time-picker {
    margin-left: 10px;
    height: 9px;
    border-radius: 15px;
    background: #DBDBE0;
    padding: 3px;
}

/* @font-face {
    font-family: 'Segoe UI Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Regular'), url('./fonts/segoe/SegoeUI.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Segoe UI Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Italic'), url('./fonts/segoe/SegoeUIBoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Segoe UI Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Bold'), url('./fonts/segoe/SegoeUIBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Segoe UI Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Bold Italic'), url('./fonts/segoe/SegoeUIBoldItalic.woff') format('woff');
    } */
.tour-step {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100000;
}

.tour-step__backdrop {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  background-color: #000;
  opacity: 0.5;
  z-index: 2;
}

.tour-step button {
  border: 0;
  border-radius: 3px;
  color: hsla(0,0%,100%,.75);
  cursor: pointer;
  margin-right: 0.5rem;
  padding: 0.5rem 1.5rem;
  transition: all .5s ease;
}

.tour-step__title__wrapper__close-btn {
  background: rgba(50, 136, 230, 1) !important;
}

.tour-step-done-btn,
.tour-step-next-btn,
.tour-step-previous-btn {
  background: rgba(12, 63, 118, 1);
}

.tour-step-done-btn:disabled,
.tour-step-next-btn:disabled {
  background: rgba(50, 136, 230, 0.8);
}

.tour-step__content {
  position: absolute;
  max-height: 70%;
  background-color: #fff;
  padding: 15px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  transition: all 300ms linear;

  -webkit-user-select: none;

          user-select: none;
  cursor: pointer;

  background: #4A9CFB;
  color: #000000;
  border-radius: 10px;
}

.tour-step__content.tour-step__content--flat {
  padding: 0;
  background: none;
}

.tour-step__title__wrapper {
  display: flex;
  align-items: center;
}

.tour-step__title__wrapper__close-btn {
  margin-left: auto;
  background: none;
  margin-right: 0;
}

.tour-step__title__wrapper__content {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.tour-step__body__wrapper {
  margin: 10px 0px;
}

.tour-target-highlight {
  z-index: 4;
  position: relative;
}

.arrow {
  content: "";
  left: -20px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 20px solid #4A9CFB; 
}

.tour-step__content:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px;
  border-color: transparent;
}

.tour-step__content[data-popper-placement="top"]:not(.tour-step__content--flat) {
  margin-bottom: 20px !important;
}

.tour-step__content[data-popper-placement="top"]:not(.tour-step__content--flat):after {
  border-bottom-width: 0;
  border-top-color: #4A9CFB;
  bottom: -20px;
  left: calc(50% - 20px);
}

.tour-step__content[data-popper-placement="bottom"]:not(.tour-step__content--flat) {
  margin-top: 20px !important;
}

.tour-step__content[data-popper-placement="bottom"]:not(.tour-step__content--flat):after {
  border-top-width: 0;
  border-bottom-color: #4A9CFB;
  top: -20px;
  left: calc(50% - 20px);
}

.tour-step__content[data-popper-placement="left"]:not(.tour-step__content--flat) {
  margin-right: 20px !important;
}

.tour-step__content[data-popper-placement="left"]:not(.tour-step__content--flat):after {
  border-right-width: 0;
  border-left-color: #4A9CFB;
  right: -20px;
  top: calc(50% - 20px);
}

.tour-step__content[data-popper-placement="right"]:not(.tour-step__content--flat) {
  margin-left: 20px !important;
}

.tour-step__content[data-popper-placement="right"]:not(.tour-step__content--flat):after {
  border-left-width: 0;
  border-right-color: #4A9CFB;
  left: -20px;
  top: calc(50% - 20px);
}
.google-btn {
    background-color: #4285f4;
    border-radius: 2px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: 51px auto;

    box-shadow: 3px 6px 20px 7px rgba(28,39,63,0.25);
    border-radius: 6px;
}

.google-btn:hover {
    box-shadow: 0 0 6px #fff;
}

.google-btn:active {
    background: #1669f2;
}

.google-text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.google-icon-wrapper {
    margin-top: 1px;
    margin-left: 1px;
    width: 50px;
    height: 50px;
    border-radius: 2px;
    background-color: #fff;
}
.google-icon {
    position: absolute;
    margin-top: 15px;
    margin-left: 15px;
    width: 22px;
    height: 22px;
}
.btn-text {
    color: #fff;
    letter-spacing: 0.2px;
    padding: 0 15px;
    font-weight: bold;
}

