.tour-step {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100000;
}

.tour-step__backdrop {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  background-color: #000;
  opacity: 0.5;
  z-index: 2;
}

.tour-step button {
  border: 0;
  border-radius: 3px;
  color: hsla(0,0%,100%,.75);
  cursor: pointer;
  margin-right: 0.5rem;
  padding: 0.5rem 1.5rem;
  transition: all .5s ease;
}

.tour-step__title__wrapper__close-btn {
  background: rgba(50, 136, 230, 1) !important;
}

.tour-step-done-btn,
.tour-step-next-btn,
.tour-step-previous-btn {
  background: rgba(12, 63, 118, 1);
}

.tour-step-done-btn:disabled,
.tour-step-next-btn:disabled {
  background: rgba(50, 136, 230, 0.8);
}

.tour-step__content {
  position: absolute;
  max-height: 70%;
  background-color: #fff;
  padding: 15px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  transition: all 300ms linear;

  user-select: none;
  cursor: pointer;

  background: #4A9CFB;
  color: #000000;
  border-radius: 10px;
}

.tour-step__content.tour-step__content--flat {
  padding: 0;
  background: none;
}

.tour-step__title__wrapper {
  display: flex;
  align-items: center;
}

.tour-step__title__wrapper__close-btn {
  margin-left: auto;
  background: none;
  margin-right: 0;
}

.tour-step__title__wrapper__content {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.tour-step__body__wrapper {
  margin: 10px 0px;
}

.tour-target-highlight {
  z-index: 4;
  position: relative;
}

.arrow {
  content: "";
  left: -20px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 20px solid #4A9CFB; 
}

.tour-step__content:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px;
  border-color: transparent;
}

.tour-step__content[data-popper-placement="top"]:not(.tour-step__content--flat) {
  margin-bottom: 20px !important;
}

.tour-step__content[data-popper-placement="top"]:not(.tour-step__content--flat):after {
  border-bottom-width: 0;
  border-top-color: #4A9CFB;
  bottom: -20px;
  left: calc(50% - 20px);
}

.tour-step__content[data-popper-placement="bottom"]:not(.tour-step__content--flat) {
  margin-top: 20px !important;
}

.tour-step__content[data-popper-placement="bottom"]:not(.tour-step__content--flat):after {
  border-top-width: 0;
  border-bottom-color: #4A9CFB;
  top: -20px;
  left: calc(50% - 20px);
}

.tour-step__content[data-popper-placement="left"]:not(.tour-step__content--flat) {
  margin-right: 20px !important;
}

.tour-step__content[data-popper-placement="left"]:not(.tour-step__content--flat):after {
  border-right-width: 0;
  border-left-color: #4A9CFB;
  right: -20px;
  top: calc(50% - 20px);
}

.tour-step__content[data-popper-placement="right"]:not(.tour-step__content--flat) {
  margin-left: 20px !important;
}

.tour-step__content[data-popper-placement="right"]:not(.tour-step__content--flat):after {
  border-left-width: 0;
  border-right-color: #4A9CFB;
  left: -20px;
  top: calc(50% - 20px);
}