@import "shepherd.js/dist/css/shepherd.css";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

html,
body {
    height: 100%;
    margin: 0;
    padding: 0;

    font-family: Segoe UI, Frutiger, Frutiger Linotype, Dejavu Sans, Helvetica Neue, Arial, sans-serif;

    background-color: #2c2c41;

    font-size: 16px;
}

@media (min-width: 1280px) { 
    html, body {
        font-size: 14px;
    }
}

#root {
    display: flex;
    flex-direction: column;
    height: 100%;
}

* {
    /* box-sizing: border-box; */
    /* border: 1px solid red; */
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus {
     outline: none;
}

.dark-select .MuiOutlinedInput-root {
    height: 20px;
    border-radius: 50px!important;
}

.dark-select .MuiOutlinedInput-root:hover {
    background: #1c1d2d;
}

.dark-select > div {
    background-color: #31334E;
    color: #606178;
    font-size: 14px;
}

.dark-select svg {
    color: #606178;
}

.day-picker .MuiCheckbox-root {
    padding-left: 0;
    padding-right: 5px;
}

.padding-top-small {
    padding-top: 10px;
}

.margin-bottom-small {
    margin-bottom: 10px;
}

.margin-left-negative-tiny {
    margin-left: -5px;
}

.break {
    flex-basis: 100%;
    height: 0;
}

.time-picker {
    margin-left: 10px;
    height: 9px;
    border-radius: 15px;
    background: #DBDBE0;
    padding: 3px;
}

/* @font-face {
    font-family: 'Segoe UI Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Regular'), url('./fonts/segoe/SegoeUI.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Segoe UI Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Italic'), url('./fonts/segoe/SegoeUIBoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Segoe UI Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Bold'), url('./fonts/segoe/SegoeUIBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Segoe UI Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Bold Italic'), url('./fonts/segoe/SegoeUIBoldItalic.woff') format('woff');
    } */