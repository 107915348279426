.google-btn {
    background-color: #4285f4;
    border-radius: 2px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: 51px auto;

    box-shadow: 3px 6px 20px 7px rgba(28,39,63,0.25);
    border-radius: 6px;
}

.google-btn:hover {
    box-shadow: 0 0 6px #fff;
}

.google-btn:active {
    background: #1669f2;
}

.google-text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.google-icon-wrapper {
    margin-top: 1px;
    margin-left: 1px;
    width: 50px;
    height: 50px;
    border-radius: 2px;
    background-color: #fff;
}
.google-icon {
    position: absolute;
    margin-top: 15px;
    margin-left: 15px;
    width: 22px;
    height: 22px;
}
.btn-text {
    color: #fff;
    letter-spacing: 0.2px;
    padding: 0 15px;
    font-weight: bold;
}
